/* Container for the gallery */
.galleryContainer{
    display: flex;
    flex-direction: column;
    margin-top: 120px;
}

.gallery-title {
    justify-content: center; /* Center the title horizontally */
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #213555 ;
}

.gallery {
    display: grid; /* Use grid instead of flex */
    grid-template-columns: repeat(4, 1fr); /* 4 images per row */
    gap: 36px; /* Space between images */
    justify-items: center; /* Center the images */
    margin: 45px auto;
}

/* Each gallery item */
.gallery-item {
    width: 300px; /* Set a fixed width */
    height: 400px; /* Set a fixed height */
    overflow: hidden; /* Ensure the image fits within the container */
    transition: transform 0.5s ease-in-out;
}
.gallery-item:hover {
    transform: scale(1.2);
    opacity: 1;
}


/* Images inside the gallery items */
.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures images fill the container while maintaining aspect ratio */
}


@media (max-width: 768px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr); /* 2 images per row */
        margin: 5px auto;
    }
    .gallery-title {
        font-size: 1.7rem;

    }
    .gallery-item {
        width: 200px;
        height: 300px;
        overflow: hidden;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0.7;
    }
    
    .gallery-item.active {
        transform: scale(1.3);
        opacity: 1;
    }
    .gallery-item:not(.active) {
        transform: scale(1);
        opacity: 0.7;
    }
}

@media (max-width: 480px) {
    .gallery {
        grid-template-columns: 1fr; /* 1 image per row */
    }
}
