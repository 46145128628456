.review {
    background: var(--clr-white);
    padding: 1.5rem 2rem;
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    transition: var(--transition);
    text-align: center;
    height: 400px; /* Fixed height */
    overflow: hidden; /* Hide overflowing content */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .review:hover {
    box-shadow: var(--dark-shadow);
  }
  .stars-img{
    padding: 0;
    width: 40%;
    height: auto;
    object-fit: contain;
  }
  .quote-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    transform: translateY(25%);
    background: var(--clr-primary-5);
    color: var(--clr-white);
  }

  .author {
    margin-bottom: 0.25rem;
    font-weight: 800;
    flex-shrink: 0;
    margin-top: 0;
  }
  .info {
    margin-bottom: 0.75rem;
    font-size: 0.85rem;
    flex-shrink: 0;
    color: black;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem; 
  }

  .prev-btn,
  .next-btn {
    color: var(--clr-primary-7);
    font-size: 1.25rem;
    background: transparent;
    border-color: transparent;
    margin: 0 0.5rem;
    transition: var(--transition);
    cursor: pointer;
  }
  .prev-btn:hover,
  .next-btn:hover {
    color: var(--clr-primary-5);
  }
  .random-btn {
    margin-top: 0.5rem;
    background: var(--clr-primary-10);
    color: var(--clr-primary-5);
    padding: 0.25rem 0.5rem;
    text-transform: capitalize;
    border-radius: var(--radius);
    transition: var(--transition);
    border-color: transparent;
    cursor: pointer;
  }
  .random-btn:hover {
    background: var(--clr-primary-5);
    color: var(--clr-primary-1);
  }
  
  .icon{
  font-size: 4rem;
  color:red;
  }
  /* Ensure arrows are visible on mobile */
@media (max-width: 768px) {
    .review {
        flex-direction: column; /* Stack items vertically */
        justify-content: flex-start; /* Keep author, image at the top */
        height: auto; /* Allow height to adjust based on content */
      }
    
      .author,
      .stars-img,
      .info {
        order: 1; /* Ensure they are at the top */
      }
    
      .button-container {
        margin-top: auto; /* Push buttons to the bottom */
      }
    
      /* Position the arrows on the left and right of the info on mobile */
      .prev-btn,
      .next-btn {
        display: block !important;
        position: absolute; /* Keep absolute positioning */
        top: 50%;
        transform: translateY(-50%); /* Vertically center the arrows */
        margin-top: 0; /* Remove any additional margin-top */
      }
    
      .prev-btn {
        left: 1px; /* Adjust the distance from the left */
      }
    
      .next-btn {
        right: 10px; /* Adjust the distance from the right */
      }
  }
  