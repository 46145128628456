/* AboutPage.css */

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #EDF2F6;
  color: white;
}

.about-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px; /* Ensures even spacing */
  min-height: 70vh;
  padding: 0 20px;
  box-sizing: border-box;
}

.aboutPageTitle {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 95px;
  color: #213555;
}

.about-text {
  flex: 1 1 50%;
  padding: 80px;
  margin-left: 60px;
  text-align: left; /* Better readability */
}

.aboutPara {
  color: #0A1931;
  line-height: 1.5;
  text-align: left; /* Left-aligned for readability */
}

.about-image {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  width: 400px; /* Adjust width as needed */
  border-radius: 8px;
}

.bookApptButton {
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #213555;
  color: white;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .about-text, .about-image {
      max-width: 100%;
      text-align: justify;
      padding: 0;
  }

  .about-image img {
      width: 100%;
  }
}
