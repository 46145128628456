.homepage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
  
}

.homepage h1,
.homepage h2,
.homepage h3 {
  position: relative;
  z-index: 2; /* Makes sure the text is above the overlay */
}

.custom-heading {
  font-family: "Playfair Display", "Roboto", sans-serif;
  margin: 0 auto;
  font-size: 3rem; /* Equivalent to text-4xl in Tailwind */
  font-weight: 700; /* Equivalent to font-bold */
  letter-spacing: -0.015em; /* Approximation for tight tracking */
  text-align: left;
}

.BookApptButton {
  background-color: white;
  color: #213555;
  padding: 25px 30px;
  border-radius: 5px;
  z-index: 2;
  text-align: center;
  margin-top: 280px;
  font-weight: 800;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1),
  background-color 0.6s ease,
  color 0.6s ease; /* Smooth transitions with longer duration */
  box-shadow: 0 2px 8px rgba(0, 10, 10, 0.9); /* Optional */
  
}

.BookApptButton:hover {
  cursor: pointer;
  background-color: #B4B4B8; 
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.HeroSection {
  background: url("../../../public/images/background.jpg") center / contain
    no-repeat;
  width: 100%;
  margin-top: -25px;
  height: 80vh; /* Ensure full viewport height */
  position: relative;
  z-index: 2;
}

.HeroSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.9); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure it appears above the background image but below text */
}

.ParaHeroSection {
  color: #0A1931;
  font-weight: 600;
  font-family: "Playfair Display", "Cormorant Unicase", "Roboto", sans-serif;
  font-size: 2.5rem;
  font-weight: 1000;
  z-index: 1;
  margin-top: 80px;
  margin-bottom: 10px;
}
.SubParaHeroSection{
  color: #0A1931;
  font-weight: 600;
  font-family: "Playfair Display", "Cormorant Unicase", "Roboto", sans-serif;
  font-size: 1.5rem;
  z-index: 2;
  margin-top: 5px;
  margin-bottom: 80px;
}

.servicesHPContainer {
  background-color: #213555;
  position: relative;
  z-index: 3;
  margin-top: -85px;
  padding: 70px 20px 100px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 80%;
}

.servicesHPGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 80px;
  margin: 0 auto;
  max-width: 1200px;
}
.titles {
  color: black;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Playfair Display";
}

.servicesHPTitle {
  color: white !important;
  font-size: 1.6rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Playfair Display";
}

.serviceHPItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  font-family: "Playfair Display";
}
.serviceItem h3 {
  color: white !important; /* Sets the text color to black */
}

.serviceIcon {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-right: 5px;
}

.galleryTitle{
  color: #0A1931;
  font-size: 2.2rem;
  font-weight: 600;
}

.galleryHP {
  background-color: #BFBBA9;
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;
  margin-bottom: 54px;
  position: relative; /* Ensure it stays above the overlay */
  z-index: 2; /* Position the gallery above the overlay */
  box-shadow: none;
  min-height: 30vh;
  display: flex;
  flex-direction: column; /* Ensures elements stack */
  align-items: center; /* Center align content */
  text-align: center; /* Align text */
}

.gridContainer{
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
}

.servicesGridCard{
  flex: 1;
  min-width: 200px; /* Adjust as needed */
  max-width: 300px;
}
.galleryCardImg {
  width: 100%;
  object-fit: cover;
  height: 400px;
  border-radius: 5px;
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}

.galleryCardImg:hover {
  transform: scale(1.1); /* Enlarge the image on hover */

}

.goToGalleryButton {
  background-color: #0A1931;
  color: #F2EFE7;
  padding: 25px 30px;
  border-radius: 5px;
  z-index: 2;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: 800;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), 
  background-color 0.6s ease, 
  color 0.6s ease; /* Smooth transitions with longer duration */
}
.goToGalleryButton:hover {
  cursor: pointer;
  background-color: #213555; 
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.reviewsAndLocationContainer {
  background-color: #BFBBA9;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;/* Full viewport width */
  gap: 20px;
  padding: 20px;
  color: black;
}
.reviewsContainer,
.mapContainer {
  background-color: white;
  width: 48%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 10, 150, 0.9); /* Optional */
  overflow: hidden;
}

.mapItem {
  height: 400px;
  width: 100%;
}

@media (min-width: 1024px) {
  .servicesGrid {
    grid-template-columns: repeat(3, 1fr); /* Ensure 3 columns on desktop */
  }
  .BookApptButton,
  .ParaHeroSection {
  margin-top: 300px;
  position: relative; /* Keeps them above the overlay */
  z-index: 2; /* Ensures they are above the overlay */
  }
  .SubParaHeroSection{
    margin-top: 30px;
    z-index: 2; 
    position: relative; 
  }
  .galleryTitle{
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .HeroSection {
    background: url("../../../public/images/background.jpg") center / cover
      no-repeat;
    width: 100%;
    height: 80vh; /* Ensure full viewport height */
    position: relative;
    z-index: 2;
  }
  .scroll-btn {
    display: none;
  }
  .homepage {
    justify-content: space-between;
    background-size: 160%;
    background-position: center top;
  }

  .BookApptButton {
    margin-top: 295px;
    position: relative; /* Keeps them above the overlay */
    z-index: 2; /* Ensures they are above the overlay */
  }

  .SubParaHeroSection {
    font-size: 1.4rem;
    margin-top: 10px;
    position: relative; /* Keeps them above the overlay */
    z-index: 2; /* Ensures they are above the overlay */
  }

  .custom-heading {
    font-size: 1.9rem; /* Equivalent to text-4xl in Tailwind */
  }

  .BookApptButton {
    padding: 10px 20px;
  }

  .BookApptButton:hover {
    cursor: pointer;
  }
  .ParaHeroSection {
    font-size: 1.6rem;
    margin-top: 25px;
  }
  .servicesHPGrid {
    grid-template-columns: repeat(2, 1fr); /* Switch to a single column */
    gap: 8px; /* Adjust the gap as needed */
  }

  .gridContainer{
    margin-left: 22px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    flex-wrap: wrap;
  }
  .galleryTitle{
    margin-bottom: 20px;
  }
  .galleryCardImg {
    width: 90%;
    object-fit: cover;
    height: 200px;
    border-radius: 5px;
  }
  .reviewsAndLocationContainer {
    flex-direction: column; /* Stack vertically */
    align-items: center; /* Center items horizontally */
    color: black;
  }

  .reviewsContainer,
  .mapContainer {
    width: 100%; /* Full width on mobile */
  }
}
