.servicesContainerPage{
    margin-top: 100px;
}

.servicesPageTitle {
    justify-content: center; /* Center the title horizontally */
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding-top: 50px;
    margin-bottom: 50px;
    color: #213555 ;
  }
  
.servicesPageGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 80%;
    column-gap: 0px;
    row-gap: 20px;
    margin: 0 auto;
}

.service-icon{
    width: 40px;
    height: 40px;
    padding: 5px;
    margin-right: 5px;
}

.servicePageitem{
    width: 90%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #F6F1F1;
    background-color: #213555;
    padding: 15px;
    box-shadow: 7px 15px 10px rgba(0, 0, 0, 0.5);
    
}

h2 {
    font-weight: bold; /* Makes the service name bold */
    font-size: 1.1rem; /* Makes the service name a bit larger */
    margin-bottom: 5px;
}

p {
    font-size: 1rem; /* Makes the description font size a little smaller than the service name */
    color: #F6F1F1; /* Optional: a darker color for the description for better readability */
}

.bookButtononServices{
    display: block;
    padding: 15px 20px ;
    border-radius: 10px;
    background-color: #213555;
    color: white ;
    margin: 60px auto;
    
}

@media (max-width: 768px) {
    .servicesPageGrid {
        grid-template-columns: 1fr; /* Switch to one column in mobile mode */
    }
    .servicePageitem{
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .servicesPageGrid {
      grid-template-columns: repeat(2, 1fr); /* Explicitly enforce 2 columns on desktop */
    }
  }